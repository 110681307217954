import "aos/dist/aos.css";
import { useEffect, useRef, useState } from "react";

export const WhatIsQargoquote = () => {
  const [visibleSections, setVisibleSections] = useState({});

  const titleRef = useRef(null);
  const introRef = useRef(null);
  const subtitleRef = useRef(null);
  const listRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisibleSections((prev) => ({
              ...prev,
              [entry.target.dataset.section]: true,
            }));
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    observer.observe(titleRef.current);
    observer.observe(introRef.current);
    observer.observe(subtitleRef.current);

    listRefs.current.forEach((item) => observer.observe(item));

    return () => observer.disconnect();
  }, []);

  return (
    <section className="bg-rose-900 p-8 mx-auto" id="Qargoquote">
      <div className="max-w-[1280px] mx-auto space-y-8">
        <h2
          ref={titleRef}
          data-section="title"
          className={`text-3xl font-bold  text-white text-center md:text-left transition-opacity duration-700 head2 ${
            visibleSections.title ? "opacity-100" : "opacity-0"
          }`}
        >
          What is Qargoquote?
        </h2>
        <p
          ref={introRef}
          data-section="intro"
          className={`text-white text-lg transition-opacity duration-700 ${
            visibleSections.intro ? "opacity-100" : "opacity-0"
          }`}
        >
          Qargoquote is Qatar’s first online B2B logistics marketplace, uniquely
          designed to transform how importers and logistics providers connect,
          compare, and collaborate. Our platform redefines logistics
          procurement, making it faster, more transparent, and cost-effective.
        </p>

        <h3
          ref={subtitleRef}
          data-section="subtitle"
          className={`text-3xl font-semibold text-white transition-opacity duration-700 ${
            visibleSections.subtitle ? "opacity-100" : "opacity-0"
          }`}
        >
          Why Choose Qargoquote?
        </h3>

        <ul className="list-disc list-inside space-y-4 text-white pl-4">
          {[
            "The only platform that can solve procurement and logistics business challenges.",
            "The place that connects importers and traders with logistics service providers.",
            "Instant Access to Competitive Quotes: Importers can post their shipment needs and receive multiple quotes from a network of verified logistics providers. No more repetitive outreach—Qargoquote brings all options together so you can make quick, informed decisions.",
            "Transparent Rate and Quality Comparisons: Compare prices and service quality with verified ratings and reviews. Our platform ensures transparency, helping importers choose providers who align with their budget and standards.",
            "A Steady Stream of Targeted Leads for Service Providers: For logistics providers, Qargoquote offers direct access to importers actively seeking services, reducing the need for traditional sales outreach.",
            "Flexible Subscription Options: Qargoquote adapts to your needs, providing platform access with the flexibility that suits your business.",
            "Reputation-Building with Ratings and Reviews: Both importers and providers benefit from a transparent rating system. Providers can build credibility, while importers can make confident choices based on feedback.",
          ].map((text, index) => (
            <li
              key={index}
              ref={(el) => (listRefs.current[index] = el)}
              data-section={`list-item-${index}`}
              className={`transition-opacity duration-700 ${
                visibleSections[`list-item-${index}`]
                  ? "opacity-100"
                  : "opacity-0"
              }`}
            >
              <strong>{text.endsWith(":") ? text.slice(0, -1) : text}</strong>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};
