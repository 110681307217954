import { Link } from "react-router-dom";
import q from "../../assets/q.png";
import { FaInstagram, FaFacebook, FaLinkedin } from "react-icons/fa";

export const Footer = () => {
  return (
    <footer id="Subscribe" className="bg-white rounded-lg shadow m-4">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <Link
            to="/"
            className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse"
          >
            <img src={q} alt="Qargo" className="w-64 ml-4" />
          </Link>

          {/* Social Media Icons */}
        

          <a
            href="https://qatar.qargoquote.com/"
            className="py-3 px-6 text-md font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 "
          >
            Get Started
          </a>
        </div>

        <p className="mt-4 text-sm text-gray-500 text-center px-4 sm:px-0 m-4  ">
          Our Office Location: Tornado Tower, Floor 22, Westbay, Doha, Qatar
        </p>  <div className="flex items-center justify-center space-x-4 mb-4 sm:mb-0">
            <a
              href="https://www.instagram.com/qargoquote/profilecard/?igsh=czg2c3M1ZHcwbzk3"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-500 hover:text-gray-700"
            >
              <FaInstagram className="text-2xl" />
            </a>
            <a
              href="https://www.facebook.com/qargoquote?mibextid=wwXIfr&rdid=X5kTpVgsmxacOfr4&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F14k1mCJCRK%2F%3Fmibextid%3DwwXIfr#"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-500 hover:text-gray-700"
            >
              <FaFacebook className="text-2xl" />
            </a>
            <a
              href="https://www.linkedin.com/company/qargoquote-website-services/about/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-500 hover:text-gray-700"
            >
              <FaLinkedin className="text-2xl" />
            </a>
          </div>

        <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />
        <span className="block text-sm text-gray-500 sm:text-center px-4 sm:px-0">
          © 2024{" "}
          <Link to="/" className="hover:underline">
            Qargoquote™
          </Link>
          . All Rights Reserved.
        </span>
      </div>
    </footer>
  );
};

export default Footer;