import { useState } from "react";
import { FaPhoneAlt, FaRegCommentDots, FaTimes } from "react-icons/fa";
import useContactUs from "../../hooks/useContactUs";

export const Form = ({ isFormOpen, setIsFormOpen }) => {
  const toggleForm = () => setIsFormOpen(!isFormOpen);

  const { sendContactUs, loading, error } = useContactUs();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });

  const [submitted, setSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setFormErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name.trim()) errors.name = "Name is required";
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = "Invalid email address";
    }
    if (!formData.number.trim()) {
      errors.number = "Phone number is required";
    }
    if (!formData.message.trim()) errors.message = "Message is required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      await sendContactUs(formData);
      setSubmitted(true);
    }
  };

  return (
    <div className="relative">
      <div
        className="fixed bottom-4 right-4 p-4 bg-white text-white rounded-full cursor-pointer shadow-lg"
        onClick={toggleForm}
      >
        <FaRegCommentDots size={24} className="text-rose-900" />
      </div>

      {/* Form */}
      {isFormOpen && (
        <div className="fixed bottom-20 right-4 w-72 p-6 bg-white border border-gray-300 shadow-xl rounded-lg">
          {/* Close Button */}
          <button
            onClick={toggleForm}
            className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
          >
            <FaTimes size={20} />
          </button>

          <h2 className="text-lg font-bold mb-4 text-gray-800 text-center">
            Contact Us
          </h2>

          {submitted ? (
            <div className="text-center">
              <p className="text-gray-950 text-sm mt-2">
                Thank you for contacting Qargoquote, our team will work on your inquiry and get back to you shortly.
              </p>
            </div>
          ) : (
            <form onSubmit={handleSubmit} noValidate>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-medium mb-2">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Your Name"
                  required
                />
                {formErrors.name && (
                  <p className="text-red-500 text-sm mt-1">{formErrors.name}</p>
                )}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-medium mb-2">
                  Phone Number
                </label>
                <input
                  type="tel"
                  name="number"
                  value={formData.number}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Phone Number"
                  required
                />
                {formErrors.number && (
                  <p className="text-red-500 text-sm mt-1">{formErrors.number}</p>
                )}
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-medium mb-2">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Your Email"
                  required
                />
                {formErrors.email && (
                  <p className="text-red-500 text-sm mt-1">{formErrors.email}</p>
                )}
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-medium mb-2">
                  How Can We Help?
                </label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Your Message"
                  rows="4"
                  required
                ></textarea>
                {formErrors.message && (
                  <p className="text-red-500 text-sm mt-1">{formErrors.message}</p>
                )}
              </div>

              <div className="mb-4 flex items-center space-x-2">
                <FaPhoneAlt size={20} className="text-green-500" />
                <a
                  href="tel:+97444294865"
                  className="text-gray-700 text-sm font-medium hover:underline"
                >
                  +974 4429 4865
                </a>
              </div>

              <button
                type="submit"
                className="w-full bg-rose-900 text-white py-2 rounded-lg hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-rose-500"
                disabled={loading}
              >
                {loading ? "Sending..." : "Send"}
              </button>

              {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
            </form>
          )}
        </div>
      )}
    </div>
  );
};

export default Form;